<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card class="modal-body">
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">New Campaign</span>
            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="validationObserver">
            <v-form ref="form" @submit.prevent="submit()">
              <div class="label mb-1">
                Campaign Name (required)
              </div>
              <validation-text-area
                v-model.trim="name"
                class="l-grey"
                label="Campaign Name"
                type="text"
                :success="false"
                name="name"
                :rules="{ required:true,max:maxName}"
                :counter="maxName"
                solo
                box
                rows="4"
                no-resize
                required
                :error-messages="errors.collect('name')"
                @input="nameInput"
              />
              <div class="label mb-1">
                Spot ID (required and only numbers greater than 0)
              </div>
              <validation-text-field
                v-model.trim="spot"
                class="d-grey"
                label="Spot ID"
                type="text"
                :success="false"
                name="spot"
                :rules="{ required:true,numeric:true,max:maxSpot, regex: /^[1-9][0-9]*$/ }"
                :counter="maxSpot"
                solo
                required
                :error-messages="errors.collect('spot')"
                @input="spotInput"
              />
              <!--              type="number" between:[1,9999999999]-->
              <div class="label mb-1">
                Type (required)
              </div>
              <v-select
                v-model="selectedType"
                :items="types"
                :error-messages="typeErrors"
                :menu-props="{ contentClass: 'select-custom-drop l-grey' }"
                color="#E0E0E0"
                item-value="value"
                item-text="title"
                name="type"
                solo
                box
                label="Select type"
                class="select-custom l-grey"
                @change="typeChange"
              />
              <v-card-actions class="pb-1 pt-3">
                <v-spacer />
                <v-btn color="error" @click.native="close">
                  Cancel
                </v-btn>
                <v-btn
                  :loading="isLoading"
                  color="success"
                  type="submit"
                >
                  Add Campaign
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createAdvertisingRequest } from "@/api/advertising"

export default {
  name: "AddCampaignModal",
  props: {
    value: Boolean,
    types: {
      type: [Array],
      default: () => []
    },
    refresh: {
      type: [Function],
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      name: null,
      spot: null,
      selectedType: "",
      typeErrors: null,
      maxName: 150,
      maxSpot: 10
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    close() {
      this.resetForm()
      this.show = false
    },
    async nameInput() {
      if (this.name && this.name.length && this.name.length > this.maxName) {
        await this.$nextTick(() => {
          this.name = this.name.slice(0, this.maxName)
        })
      }
    },
    spotInput() {
      if (this.spot && this.spot.length && this.spot.length > this.maxSpot) {
        this.$nextTick(() => {
          this.spot = this.spot.slice(0, this.maxSpot)
        })
      }
    },
    typeChange() {
      this.typeErrors = null
    },
    async submit() {
      const valid = await this.$refs.validationObserver.validate()

      if (!this.selectedType || !valid) {
        !this.selectedType
          ? (this.typeErrors = "The type field is required.")
          : (this.typeErrors = null)
        return
      }
      this.errors.clear()
      this.typeErrors = null
      await this.createAdvertising()
    },

    resetForm() {
      this.name = null
      this.spot = null
      this.$refs.form.reset()
      // this.$nextTick(() => {
      this.$refs.validationObserver.reset()
      // });
    },
    async createAdvertising() {
      this.isLoading = true
      const params = {
        spot_id: this.spot,
        title: this.name,
        type: this.selectedType
      }
      await createAdvertisingRequest(params)
        .then(() => {
          this.refresh()
          this.$notificationShow("Campaign is successfully created!")
          this.resetForm()
          this.show = false
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
