<template>
  <div>
    <v-dialog :value="show" max-width="500px" @input="close()">
      <v-card class="modal-body">
        <v-layout align-center justify-end>
          <div class="btn-close">
            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-layout>

        <v-card-text>
          <span class="modal-text break">
            If you delete this campaign, you will no longer see accurate data in its history.
            But all payments to users and their earnings on this advertising will be saved.
            Confirm campaign deletion?
          </span>
        </v-card-text>

        <v-card-actions class="px-3 pt-3 pb-4">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            No
          </v-btn>
          <v-btn color="success" :loading="isLoading" @click.native="deleteCurrentCampaign()">
            Yes, confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteAdvertising } from "@/api/advertising"

export default {
  name: "DeleteCampaignModal", 
  props: {
    value: Boolean,
    item: {
      type: [Object],
      default: () => {}
    },
    refresh: {
      type: [Function],
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    async deleteCurrentCampaign() {
      this.isLoading = true
      await deleteAdvertising(this.item.id)
        .then(() => {
          this.refresh()
          this.$notificationShow(
            `The campaign ${this.item.title} is successfully deleted`
          )
          this.show = false
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
<style scoped>
.modal-text{
  font-size: 16px;
}
</style>
