<template>
  <div>
    <AdvertisingActiveList />
  </div>
</template>

<script>
import AdvertisingActiveList from "../../../components/Advertising/AdvertisingActiveList"
export default {
  name: "AdvertisingActive",
  components: {
    AdvertisingActiveList
  }
}
</script>

<style scoped>

</style>