<template>
  <div>
    <v-layout row class="mb-3">
      <v-flex xs3>
        <v-btn-toggle class="table-top-menu">
          <v-btn :to="{ name: 'advertising_active' }" flat large>
            Active
          </v-btn>

          <v-btn :to="{ name: 'advertising_archived' }" flat large>
            Archived
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex xs3>
        <TableSearch
          :label="'Search for Active Campaign'"
          :max-search="150"
          @search="searchCampaign"
        />
      </v-flex>
      <v-flex xs6 class="t-right">
        <v-btn
          color="success"
          @click="openNewModal"
        >
          New Campaign
        </v-btn>
      </v-flex>
    </v-layout>

    <v-data-table
      :items="dataList"
      :pagination.sync="pagination"
      :loading="isLoading"
      :total-items="total"
      :rows-per-page-items="[10, 25, 50, 100]"
      class="elevation-1"
      :headers="headers"
      :headers-length="5"
    >
      <template slot="items" slot-scope="props">
        <td class="td-title break">
          {{ props.item.title }}
        </td>
        <td class="td-title break td-spot">
          {{ props.item.spot_id }}
        </td>
        <td class="td-title break td-type">
          {{ props.item.type }}
        </td>
        <td class="text-xs-left break td-date">
          {{ dateFormat(props.item.date) }}
        </td>
        <td
          class="text-xs-right td-a"
        >
          <v-btn
            color="warning"
            class="ma-0"
            @click="openEditModal(props.item)"
          >
            Settings
          </v-btn>
          <v-btn
            color="error"
            class="ma-0 ml-3"
            @click="openDeleteModal(props.item)"
          >
            Delete
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        @input="refreshPagination"
      />
    </div>

    <add-campaign-modal
      v-model="newModal"
      :types="types"
      :refresh="refresh"
    />
    
    <edit-campaign-modal
      v-model="editModal"
      :item="item"
      :types="types"
      :refresh="refresh"
    />

    <delete-campaign-modal
      v-model="deleteModal"
      :item="item"
      :refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { fetchData } from "../../utils/response"
import { getAdvertisingRequest } from "../../api/advertising"
import moment from "moment"
import SavesPagination from "../../mixins/SavesPaginationMixin"
import EditCampaignModal from "@/components/Advertising/EditCampaignModal"
import DeleteCampaignModal from "@/components/Advertising/DeleteCampaignModal"
import AddCampaignModal from "@/components/Advertising/AddCampaignModal"
import TableSearch from "@/components/Elements/TableSearch"

export default {
  name: "AllUsersList",
  components: {
    EditCampaignModal,
    DeleteCampaignModal,
    AddCampaignModal,
    TableSearch,
  },
  mixins: [SavesPagination],
  data() {
    return {
      headers: [
        { text: "Campaign Name", sortable: false },
        { text: "Spot Id", sortable: false },
        { text: "Campaign Type",sortable: false },
        { text: "Date", sortable: false },
        { text: "", sortable: false },
      ],
      item: null,
      types: [],
      newModal: false,
      editModal: false,
      deleteModal: false,
      pagination: {},
      isLoading: false,
      total: 0,
      search: null,
      dataList: [],
    }
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  async mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  // created() {
  //   this.$bus.on("user-search-input", this.onSearchUser)
  // },
  // destroyed() {
  //   this.search = null
  //   this.$bus.off("user-search-input", this.onSearchUser)
  // },
  methods: {
    openNewModal() {
      this.newModal = true
    },
    openDeleteModal(item) {
      this.item = item
      this.deleteModal = true
    },
    openEditModal(item) {
      this.item = item
      this.editModal = true
    },
    searchCampaign(val) {
      this.search = val
      this.refresh()
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        search: this.search,
        status: "active"
      }
      this.dataList = await getAdvertisingRequest(params)
          .then(response => {
            this.types = response.data.types
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
          })
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.refresh()
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  },
}
</script>
<style scoped>
>>>.v-datatable.v-table thead tr:first-of-type {
  display: table-row;
}
.t-right{
  text-align: right;
}
.table-top-menu >>> .v-btn{
  min-width: 120px;
}
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.td-a{
  min-width: 220px;
}
.td-date{
  min-width: 94px;
}
.td-type{
  min-width: 120px;
}
.td-spot{
  min-width: 120px;
}
.td-title{
  width: 100%;
}
</style>
